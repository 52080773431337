import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { action as modalAction } from 'store/modal'
import { selector as sectionSelector, action as sectionAction } from 'store/section'

import SectionForm from 'components/ui/form/sectionForm'

const ModalSectionUpdate = ({id}) => {

    const dispatch = useDispatch()

    const section = useSelector(sectionSelector.byId(id))

    async function onSubmit (payload) {
        console.log('onSubmit()', payload)
        await dispatch(sectionAction.update(payload)).unwrap()
        dispatch(modalAction.close())
    }

    return <SectionForm type="update" startData={section} onSubmit={onSubmit}/>
}

ModalSectionUpdate.propTypes = {
    id: PropTypes.string,
}

export default ModalSectionUpdate
