import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { action as productAction } from 'store/product'
import { action as modalAction } from 'store/modal'

import ProductForm from 'components/ui/form/productForm'

const ModalProductCreate = () => {

    const dispatch = useDispatch()

    async function onSubmit (payload) {
        await dispatch(productAction.create(payload)).unwrap()
        dispatch(modalAction.close())
    }

    return <ProductForm onSubmit={onSubmit}/>
}

export default ModalProductCreate
