import React from 'react'
import ReactDOM from 'react-dom/client'
import { Router, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createStore } from 'store/createStore'
import history from 'utils/history'

import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css'

const store = createStore()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter history={history}>
        {/*<React.StrictMode>*/}
        <Provider store={store}>
            <App/>
        </Provider>
        {/*</React.StrictMode>*/}
    </BrowserRouter>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
