import React from 'react'
import { useDispatch } from 'react-redux'

import { action as sectionAction } from 'store/section'
import { action as modalAction } from 'store/modal'

import SectionForm from 'components/ui/form/sectionForm'

const ModalSectionCreate = () => {

    const dispatch = useDispatch()

    async function onSubmit (payload) {
        await dispatch(sectionAction.create(payload)).unwrap()
        dispatch(modalAction.close())
    }

    return <SectionForm onSubmit={onSubmit}/>

}

export default ModalSectionCreate
