import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { selector as mealSelector, action as mealAction,  } from 'store/meal'
import { action as modalAction } from 'store/modal'

import MealForm from 'components/ui/form/mealForm'

const ModalMealUpdate = ({id}) => {

    const dispatch = useDispatch()

    const meal = useSelector(mealSelector.byId(id))

    async function onSubmit (payload) {
        console.log('onSubmit()', payload)
        await dispatch(mealAction.update(payload)).unwrap()
        dispatch(modalAction.close())
    }

    return <MealForm type="update" startData={meal} onSubmit={onSubmit}/>
}

ModalMealUpdate.propTypes = {
    id: PropTypes.string,
}

export default ModalMealUpdate
