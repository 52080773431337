import React from 'react'

const Forbidden = () => {
    return (
        <div className="container d-flex justify-content-center align-items-center preloader" style={{height: '100vh'}}>
            <h1>FORBIDDEN</h1>
        </div>
    )
}

export default Forbidden
