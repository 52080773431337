import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { action as modalAction } from 'store/modal'
import { action as mealAction, selector as mealSelector } from 'store/meal'

import MealCard from 'components/ui/card/meal'
import MEAL_METHOD from './methods'

const ModalMealView = ({id}) => {

    const dispatch = useDispatch()
    const meal = useSelector(mealSelector.byId(id))

    async function onUpdate (id) {
        // console.log('productHandler.onUpdate', id)
        const modalParams = {title: 'Редактировать комбинацию', body: MEAL_METHOD.UPDATE}
        dispatch(modalAction.open(modalParams, {id}))
    }

    async function onDelete (id) {
        await dispatch(mealAction.delete(id)).unwrap()
        dispatch(modalAction.close())
    }

    return <MealCard data={meal} onUpdate={() => onUpdate(id)} onDelete={() => onDelete(id)} />
}

ModalMealView.propTypes = {
    id: PropTypes.string,
}

export default ModalMealView
