import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selector as userSelector, selector as authSelector } from 'store/user'
import { selector, action } from 'store/product'
import ControlsPanel from 'components/common/controlsPanel'
import PropTypes from 'prop-types'
import FeedsGroup from 'components/ui/dashboard/feedsGroup'
import LoadingLayout from 'layouts/loading'

const Meal = ({data, onUpdate, onDelete}) => {
    const id = data?._id

    if (!id) return <LoadingLayout/>

    const {userId, isAdmin} = useSelector(authSelector.authData())
    const profile = useSelector(userSelector.byId(data.user))

    return (
        <div className="card mb-2">
            <div className="card-header d-flex justify-content-between">
                <h6>{data.name}</h6>
                {
                    (userId === data.user || isAdmin)
                    &&
                    <div className="controls">
                        <ControlsPanel id={id} prefix="/meals/" onUpdate={onUpdate} onDelete={onDelete}/>
                    </div>
                }
            </div>
            <FeedsGroup data={data.products}/>
        </div>
    )

}

Meal.propTypes = {
    data: PropTypes.object,
    products: PropTypes.array,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
}

export default Meal
