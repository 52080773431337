import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import NavProfile from './navProfile'
import { selector as userSelector } from 'store/user'

function NavBar () {
    const [open, setOpen] = useState(true)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const isAuthorized = useSelector(userSelector.isAuthorized())
    const {isAdmin} = useSelector(userSelector.authData())

    const onClickCollapse = () => {
        setOpen(!open)
    }

    const onClickDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid px-2">
                <a className="navbar-brand" href="/">
                    <span className="fs-6 d-md-none">ЖРЕМС</span>
                    <span className="d-none d-md-block">ЖРЕМС</span>
                </a>
                <button className="navbar-toggler" type="button" onClick={onClickCollapse}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={'collapse navbar-collapse' + (!open ? ' show' : '')} id="navbarNav">
                    <ul className="navbar-nav flex-grow-1">
                        <li className="nav-item d-flex align-items-center">
                            <NavLink className="nav-link me-2" to="/products">Продукты</NavLink>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                            <NavLink className="nav-link me-2" to="/sections">Разделы</NavLink>
                        </li>
                        <li className="nav-item d-flex align-items-center">
                            <NavLink className="nav-link me-2" to="/meals">Комбинации</NavLink>
                        </li>
                        {
                            isAdmin
                            &&
                            <li className="nav-item d-flex dropdown align-items-center">
                                <div>
                                    <a
                                        className="nav-link dropdown-toggle me-2"
                                        href="#"
                                        role="button"
                                        onClick={onClickDropdown}
                                    >Admin</a>
                                    <ul className={'dropdown-menu'  + (dropdownOpen ? ' show' : '')}>
                                        <li><NavLink className="dropdown-item me-2" to="/users">Пользователи</NavLink></li>
                                        {/*<li><a className="dropdown-item" href="#">Another action</a></li>*/}
                                        {/*<li><hr className="dropdown-divider"/></li>*/}
                                        {/*<li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                                    </ul>
                                </div>
                            </li>
                        }
                        <li className="nav-item d-flex justify-content-end flex-grow-1">
                            <NavProfile/>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

NavBar.propTypes = {
    // pages: PropTypes.array.isRequired,
}

export default NavBar
