import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { action as mealAction } from 'store/meal'
import { action as modalAction } from 'store/modal'

import MealForm from 'components/ui/form/mealForm'

const ModalMealCreate = () => {

    const dispatch = useDispatch()

    async function onSubmit (payload) {
        await dispatch(mealAction.create(payload)).unwrap()
        dispatch(modalAction.close())
    }

    return <MealForm type="create" startData={{}} onSubmit={onSubmit}/>
}

export default ModalMealCreate
