import React from 'react'

const NotFound = () => {
    return (
        <div className="container d-flex justify-content-center align-items-center preloader" style={{height: '100vh'}}>
            <h1>NOT FOUND</h1>
        </div>
    )
}

export default NotFound
