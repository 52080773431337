import React from 'react'
import PropTypes from 'prop-types'
import SelectField from 'components/common/form/selectField'

const SectionFilter = ({items, currentItem, valuePath, namePath, onSelect: handleSelect}) => {
    function onSelect ({value: id}) {
        handleSelect(items.find(s => s._id === id))
    }

    if (items && Object.keys(items).length) {
        return (
            <>
                <SelectField
                    label="Выберите раздел"
                    name="meal"
                    className="col-12 mb-4"
                    value={currentItem._id}
                    // defaultValue="Все"
                    // error={mealIdError}
                    options={Object.values(items).map(m => ({label: m.name, value: m._id}))}
                    onChange={onSelect}
                />
                {
                    false
                    &&
                    <ul className="list-group list-group-horizontal mb-3">
                        {
                            Object.values(items).map(el => {
                                return (
                                    <li className={'list-group-item' + (el._id === currentItem._id ? ' active' : '')}
                                        role="button"
                                        key={el[valuePath]}
                                        onClick={() => handleSelect(el)}
                                    >
                                        {el[namePath]}
                                    </li>
                                )
                            })
                        }
                    </ul>
                }
            </>
        )
    }
}

SectionFilter.defaultProps = {
    valuePath: '_id',
    namePath: 'name',
}
SectionFilter.propTypes = {
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    currentItem: PropTypes.object,
    valuePath: PropTypes.string.isRequired,
    namePath: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default SectionFilter
