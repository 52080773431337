import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'

import { selector as authSelector } from 'store/user'
import { selector as productSelector, action as productAction } from 'store/product'
import { selector as sectionSelector } from 'store/section'
import { action as modalAction } from 'store/modal'

import Table from 'components/ui/table/productTable'
import Pagination from 'components/common/pagination'
import SectionFilter from 'components/common/sectionFilter'
import SearchString from 'components/ui/searchString'
import Button from 'components/common/buttons'
import OnlyMySelector from 'components/common/onlyMySelector'
import PRODUCT_METHOD from 'components/modal/product/methods'

import paginate from 'utils/paginate'

const defaultSection = {
    '_id': '',
    'name': 'Все',
}

const List = () => {

    const dispatch = useDispatch()

    const products = useSelector(productSelector.get())
    const sections = useSelector(sectionSelector.get())
    const {userId} = useSelector(authSelector.authData())
    const settings = useSelector(authSelector.settings())

    const pageSize = 20
    const [currentPage, setCurrentPage] = useState(1)
    const [currentSort, setCurrentSort] = useState({path: 'name', order: 'asc'})
    const [currentSection, setCurrentSection] = useState(defaultSection)
    const [searchQuery, setSearchQuery] = useState('')


    useEffect(() => {
        setCurrentPage(1)
    }, [searchQuery])

    const paginationHandler = {
        onChange: function (index) {
            setCurrentPage(index)
        }
    }

    const tableHandler = {
        onSort: function (item) {
            setCurrentSort(item)
        },
    }

    const searchHandler = {
        onSubmit: function (value) {
            setSearchQuery(value)
        },
    }

    const filterHandler = {
        onSelect: function (value) {
            console.log('onSelect()', value)
            setCurrentSection(value)
            if (currentPage !== 1)
                setCurrentPage(1)
        },
    }

    const productHandler = {
        onView: function (id) {
            // console.log('productHandler.onCreate', PRODUCT_METHOD)
            const modalParams = {title: 'Информация о продукте', body: PRODUCT_METHOD.VIEW}
            dispatch(modalAction.open(modalParams, {id}))
        },
        onCreate: function () {
            // console.log('productHandler.onCreate', PRODUCT_METHOD)
            const modalParams = {title: 'Добавить продукт', body: PRODUCT_METHOD.CREATE}
            dispatch(modalAction.open(modalParams, {}))
        },
        onUpdate: function (id) {
            // console.log('productHandler.onUpdate', id)
            const modalParams = {title: 'Редактировать продукт', body: PRODUCT_METHOD.UPDATE}
            dispatch(modalAction.open(modalParams, {id}))
        },
        onDelete: function (id) {
            // console.log('productHandler.onDelete', id)
            dispatch(productAction.delete(id))
        },
    }

    function filter (data) {
        if (!data) return []

        let filteredData = [...data]

        if (currentSection._id) {
            filteredData = data.filter(p => p.section === currentSection._id)
        }

        if (settings.onlyMy) {
            filteredData = data.filter(p => p.user === userId)
        }

        if (!!searchQuery) {
            const regexp = new RegExp(searchQuery, 'ig')
            filteredData = filteredData.filter(p => regexp.test(p.name))
        }

        return filteredData
    }

    const filteredProducts = filter(products)
    const count = filteredProducts.length
    const sortedProducts = _.orderBy(filteredProducts, currentSort.path, currentSort.order)
    const crop = paginate(sortedProducts, currentPage, pageSize)

    return (
        <>
            <div className="row justify-content-between">
                <div className="col-6 col-lg-3">
                    <Button.Back to=".."/>
                </div>
                <div className="col-6 col-lg-3">
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-success mb-1 w-100"
                        onClick={productHandler.onCreate}
                    >
                        <i className="bi bi-plus "/>
                        Продукт
                    </button>
                    {/*<NavLink to="create" className="btn btn-sm btn-outline-success mb-1 w-100">*/}
                    {/*    <i className="bi bi-plus "/>*/}
                    {/*    Продукт*/}
                    {/*</NavLink>*/}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/*<SearchStatus value={count}/>*/}
                    <SearchString
                        query={searchQuery}
                        onSubmit={searchHandler.onSubmit}
                    />
                    <SectionFilter
                        currentItem={currentSection}
                        items={[defaultSection, ...sections]}
                        namePath="name"
                        valuePath="_id"
                        onSelect={filterHandler.onSelect}
                    />
                    <OnlyMySelector/>
                    <Table
                        products={crop}
                        currentSort={currentSort}
                        onView={productHandler.onView}
                        onUpdate={productHandler.onUpdate}
                        onDelete={productHandler.onDelete}
                        onSort={tableHandler.onSort}
                    />
                    <div className="d-flex justify-content-center">
                        <Pagination
                            currentPage={currentPage}
                            pageSize={pageSize}
                            itemsCount={count}
                            onChange={paginationHandler.onChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default List
