import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'

import { selector as authSelector } from 'store/user'
import { action as modalAction } from 'store/modal'
import { action as mealAction, selector as mealSelector } from 'store/meal'

import SearchString from 'components/ui/searchString'
import Table from 'components/ui/table/mealTable'
import Pagination from 'components/common/pagination'
import Button from 'components/common/buttons'
import OnlyMySelector from 'components/common/onlyMySelector'
import MEAL_METHOD from 'components/modal/meal/methods'

import paginate from 'utils/paginate'
const List = () => {

    const dispatch = useDispatch()

    const meals = useSelector(mealSelector.get())
    const {userId} = useSelector(authSelector.authData())
    const settings = useSelector(authSelector.settings())

    const pageSize = 20
    const [currentPage, setCurrentPage] = useState(1)
    const [currentSort, setCurrentSort] = useState({path: 'name', order: 'asc'})
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        setCurrentPage(1)
    }, [searchQuery])

    const paginationHandler = {
        onChange: function (index) {
            setCurrentPage(index)
        }
    }

    const tableHandler = {
        onSort: function (item) {
            setCurrentSort(item)
        },
    }

    const mealHandler = {
        onView: function (id) {
            // console.log('productHandler.onCreate', MEAL_METHOD)
            const modalParams = {title: 'Информация о комбинации', body: MEAL_METHOD.VIEW}
            dispatch(modalAction.open(modalParams, {id}))
        },
        onCreate: function () {
            // console.log('productHandler.onCreate', MEAL_METHOD)
            const modalParams = {title: 'Добавить комбинацию', body: MEAL_METHOD.CREATE}
            dispatch(modalAction.open(modalParams, {}))
        },
        onUpdate: function (id) {
            // console.log('mealHandler.onUpdate', id)
            const modalParams = {title: 'Редактировать комбинацию', body: MEAL_METHOD.UPDATE}
            dispatch(modalAction.open(modalParams, {id}))
        },
        onDelete: function (id) {
            // console.log('mealHandler.onDelete', id)
            dispatch(mealAction.delete(id))
        },
    }

    const searchHandler = {
        onSubmit: function (value) {
            setSearchQuery(value)
        },
    }

    function filter (data) {
        if (!data)
            return []

        let filteredData = [...data]

        if (settings.onlyMy) {
            filteredData = data.filter(m => m.user === userId)
        }

        if (!!searchQuery) {
            const regexp = new RegExp(searchQuery, 'ig')
            filteredData = data.filter(m => regexp.test(m.name))
        }

        return filteredData
    }

    const filteredItems = filter(meals)
    const count = filteredItems.length
    const sortedProducts = _.orderBy(filteredItems, currentSort.path, currentSort.order)
    const crop = paginate(sortedProducts, currentPage, pageSize)
    // console.log(filteredItems, crop)

    return (
        <>
            <div className="row justify-content-between">
                <div className="col-6 col-lg-3">
                    <Button.Back to=".."/>
                </div>
                <div className="col-6 col-lg-3">
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-success mb-1 w-100"
                        onClick={mealHandler.onCreate}
                    >
                        <i className="bi bi-plus "/>
                        Комбинация
                    </button>
                    {/*<NavLink to="create" className="btn btn-sm btn-outline-success w-100">*/}
                    {/*    <i className="bi bi-plus"/>*/}
                    {/*    Комбинация*/}
                    {/*</NavLink>*/}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/*<SearchStatus value={count}/>*/}
                    <SearchString
                        query={searchQuery}
                        onSubmit={searchHandler.onSubmit}
                    />
                    <OnlyMySelector />
                    <Table
                        products={crop}
                        currentSort={currentSort}
                        onView={mealHandler.onView}
                        onUpdate={mealHandler.onUpdate}
                        onDelete={mealHandler.onDelete}
                        onSort={tableHandler.onSort}
                    />
                    <div className="d-flex justify-content-center">
                        <Pagination
                            currentPage={currentPage}
                            pageSize={pageSize}
                            itemsCount={count}
                            onChange={paginationHandler.onChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default List
