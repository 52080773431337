import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'

import { action as modalAction } from 'store/modal'
import { selector as authSelector } from 'store/user'
import { selector as sectionSelector, action as sectionAction} from 'store/section'

import Pagination from 'components/common/pagination'
import Table from 'components/ui/table/sectionTable'
import SearchString from 'components/ui/searchString'
import Button from 'components/common/buttons'
import OnlyMySelector from 'components/common/onlyMySelector'

import paginate from 'utils/paginate'
import SECTION_METHOD from 'components/modal/section/methods'

const List = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {userId} = useSelector(authSelector.authData())
    const sections = useSelector(sectionSelector.get())
    const settings = useSelector(authSelector.settings())

    const pageSize = 20
    const [currentPage, setCurrentPage] = useState(1)
    const [currentSort, setCurrentSort] = useState({path: 'name', order: 'asc'})
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        setCurrentPage(1)
    }, [searchQuery])

    const paginationHandler = {
        onChange: function (index) {
            setCurrentPage(index)
        }
    }

    const tableHandler = {
        onSort: function (item) {
            setCurrentSort(item)
        },
    }

    const sectionHandler = {
        onView: function (id) {
            // console.log('sectionHandler.onCreate', PRODUCT_METHOD)
            const modalParams = {title: 'Информация о разделе', body: SECTION_METHOD.VIEW}
            dispatch(modalAction.open(modalParams, {id}))
        },
        onCreate: function () {
            // console.log('sectionHandler.onCreate', PRODUCT_METHOD)
            const modalParams = {title: 'Добавить раздел', body: SECTION_METHOD.CREATE}
            dispatch(modalAction.open(modalParams, {}))
        },
        onUpdate: function (id) {
            // console.log('sectionHandler.onUpdate', id)
            const modalParams = {title: 'Редактировать раздел', body: SECTION_METHOD.UPDATE}
            dispatch(modalAction.open(modalParams, {id}))
        },
        onDelete: function (id) {
            // console.log('sectionHandler.onDelete', id)
            dispatch(sectionAction.delete(id))
        },
    }

    const searchHandler = {
        onSubmit: function (value) {
            setSearchQuery(value)
        },
    }

    function filter (data) {
        if (!data) return []

        let filteredData = [...data]

        if (settings.onlyMy) {
            filteredData = data.filter(p => p.user === userId)
        }

        if (!!searchQuery) {
            const regexp = new RegExp(searchQuery, 'ig')
            filteredData = filteredData.filter(p => regexp.test(p.name))
        }

        return filteredData
    }

    const filteredItems = filter(sections)

    const count = filteredItems.length
    const sortedItems = _.orderBy(filteredItems, currentSort.path, currentSort.order)
    const crop = paginate(sortedItems, currentPage, pageSize)

    return (
        <>
            <div className="row justify-content-between mb-3">
                <div className="col-6 col-lg-3">
                    <Button.Back to=".." />
                </div>
                <div className="col-6 col-lg-3">
                    <button
                        type="button"
                        className="btn btn-sm btn-outline-success mb-1 w-100"
                        onClick={sectionHandler.onCreate}>
                        <i className="bi bi-plus "/>
                        Раздел
                    </button>
                    {/*<NavLink to="create" className="btn btn-sm btn-outline-success w-100">*/}
                    {/*    <i className="bi bi-plus"/>*/}
                    {/*    Раздел*/}
                    {/*</NavLink>*/}
                </div>
            </div>
            <SearchString
                query={searchQuery}
                onSubmit={searchHandler.onSubmit}
            />
            <OnlyMySelector />
            <Table
                sections={crop}
                currentSort={currentSort}
                onView={sectionHandler.onView}
                onUpdate={sectionHandler.onUpdate}
                onDelete={sectionHandler.onDelete}
                onSort={tableHandler.onSort}
            />
            <div className="d-flex justify-content-center">
                <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={count}
                    onChange={paginationHandler.onChange}
                />
            </div>
        </>
    )
}

export default List
