import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { action as modalAction } from 'store/modal'
import { action as sectionAction, selector as sectionSelector } from 'store/section'

import SectionCard from 'components/ui/card/section'
import SECTION_METHOD from './methods'

const ModalSectionView = ({id}) => {

    const dispatch = useDispatch()
    const section = useSelector(sectionSelector.byId(id))

    async function onUpdate (id) {
        // console.log('productHandler.onUpdate', id)
        const modalParams = {title: 'Редактировать раздел', body: SECTION_METHOD.UPDATE}
        dispatch(modalAction.open(modalParams, {id}))
    }

    async function onDelete (id) {
        await dispatch(sectionAction.delete(id)).unwrap()
        dispatch(modalAction.close())
    }

    return <SectionCard data={section} onUpdate={() => onUpdate(id)} onDelete={() => onDelete(id)} />
}

ModalSectionView.propTypes = {
    id: PropTypes.string,
}

export default ModalSectionView
