import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { selector as productSelector, action as productAction,  } from 'store/product'
import { action as modalAction } from 'store/modal'

import ProductForm from 'components/ui/form/productForm'

const ModalProductUpdate = ({id}) => {

    const dispatch = useDispatch()

    const product = useSelector(productSelector.byId(id))

    async function onSubmit (payload) {
        console.log('onSubmit()', payload)
        await dispatch(productAction.update(payload)).unwrap()
        dispatch(modalAction.close())
    }

    return <ProductForm type="update" startData={product} onSubmit={onSubmit}/>
}

ModalProductUpdate.propTypes = {
    id: PropTypes.string,
}

export default ModalProductUpdate
