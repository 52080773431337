import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { action as modalAction } from 'store/modal'
import { action as productAction, selector as productSelector } from 'store/product'

import ProductCard from 'components/ui/card/product'
import PRODUCT_METHOD from './methods'

const ModalProductView = ({id}) => {

    const dispatch = useDispatch()
    const product = useSelector(productSelector.byId(id))

    async function onUpdate (id) {
        // console.log('productHandler.onUpdate', id)
        const modalParams = {title: 'Редактировать продукт', body: PRODUCT_METHOD.UPDATE}
        dispatch(modalAction.open(modalParams, {id}))
    }

    async function onDelete (id) {
        await dispatch(productAction.delete(id)).unwrap()
        dispatch(modalAction.close())
    }

    return <ProductCard data={product} onUpdate={() => onUpdate(id)} onDelete={() => onDelete(id)} />
}

ModalProductView.propTypes = {
    id: PropTypes.string,
}

export default ModalProductView
