import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { selector as userSelector } from 'store/user'
import { selector as sectionSelector } from 'store/section'

import ControlsPanel from 'components/common/controlsPanel'
import LoadingLayout from 'layouts/loading'
import calculateCalories from 'utils/calculateCalories'

const ProductCard = ({data, onUpdate, onDelete}) => {
    const id = data?._id

    if (!id) return <LoadingLayout/>

    const {userId, isAdmin} = useSelector(userSelector.authData())
    const profile = useSelector(userSelector.byId(data.user))
    const section = useSelector(sectionSelector.byId(data.section))

    return (
        <div className="card mb-2">
            <div className="card-header d-flex justify-content-between">
                <h6>{data.name}</h6>
                {
                    (userId === data.user || isAdmin)
                    &&
                    <div className="controls">
                        <ControlsPanel id={id} prefix="/products/" onUpdate={onUpdate} onDelete={onDelete}/>
                    </div>
                }
            </div>

            <div className="card-body container-fluid">
                {
                    isAdmin &&
                    <>
                        {id && <p>ID: {id}</p>}
                        {profile && <p>USER: {profile.name}</p>}
                    </>
                }
                {data.desc && <p>Описание: <br/> {data.desc}</p>}
                {section && <p>Раздел: {section.name}</p>}
                <p>
                    Белки/100:&nbsp;
                    <span className="badge bg-info">{data.proteins}</span>
                </p>
                <p>
                    Жиры/100:&nbsp;
                    <span className="badge bg-warning">{data.fats}</span>
                </p>
                <p>
                    Углеводы/100:&nbsp;
                    <span className="badge bg-success">{data.carbohydrates}</span>
                </p>
                <p>
                    Калорийность/100:&nbsp;
                    <span className="badge bg-danger">{calculateCalories(data)}</span>
                </p>
                <p>
                    Вес по умолчанию:&nbsp;
                    <span className="badge bg-dark">{data.weight}</span>
                </p>
            </div>
        </div>
    )

}

ProductCard.propTypes = {
    data: PropTypes.object,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ProductCard
